@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-pattern {
    background-image: url("../src//assets/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .scanner span {
    color: transparent;
    font-size: 1.4rem;
    position: relative;
    overflow: hidden;
    white-space: nowrap; /* Prevents text wrapping */
    letter-spacing: 0.5em; /* Adds space between letters */
  }
  
  .scanner span::before {
    content: "VIABLYSUSTAINABLE"; /* Text in all caps with spaces */
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-right: 4px solid #096960; /* Updated color to #096960 */
    overflow: hidden;
    color: #096960; /* Updated text color to #096960 */
    animation: load91371 6s linear infinite; /* Increased duration for full text */
  }
  
  @keyframes load91371 {
    0%, 10% {
      width: 0;
    }
    60%, 100% {
      width: 100%;
    }
    10%, 100% {
      border-right-color: transparent;
    }
    11%, 91% {
      border-right-color: #096960;
    }
  }
  